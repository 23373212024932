<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <div class="panel">
          <div class="panel-heading bg-dark text-light">
            <h3 class="panel-title bold">Form Lokasi</h3>
          </div>
          <div class="panel-body">
            <div class="row">
              <div class="col-md-12 mb-4">
                <GmapMap
                  class="bg-black height-sm"
                  :zoom="13"
                  :center="{
                    lat: Number(form.lat_lokasi),
                    lng: Number(form.long_lokasi),
                  }"
                >
                  <GmapMarker
                    v-for="(marker, index) in markers"
                    :key="index"
                    :position="marker.position"
                    :clickable="true"
                    :draggable="true"
                    @click="handleMarkerClick"
                    @dragend="handleMarkerDragEnd"
                  ></GmapMarker>
                </GmapMap>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label class="f-w-600" for="id_area">Nama Area </label>
                  <select
                    v-model="form.id_area"
                    type="text"
                    class="form-control"
                    required
                    @change="formValidate.id_area = ''"
                    :class="{
                      'is-valid': form.id_area != '',
                      'is-invalid': formValidate.id_area || form.id_area == '',
                    }"
                  >
                    <option value="" disabled>-Pilih-</option>
                    <option
                      v-for="area in listMasterArea"
                      :key="area.id_area"
                      :value="area.id_area"
                    >
                      {{ area.nama_area }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label class="f-w-600" for="nama_lokasi">Nama Lokasi </label>
                  <input
                    v-model="form.nama_lokasi"
                    type="text"
                    class="form-control"
                    placeholder="Nama Lokasi"
                    required
                    @input="formValidate.nama_lokasi = ''"
                    :class="{
                      'is-valid': form.nama_lokasi != '',
                      'is-invalid':
                        formValidate.nama_lokasi || form.nama_lokasi == '',
                    }"
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label class="f-w-600" for="lat_lokasi"
                    >Latitude Lokasi
                  </label>
                  <input
                    v-model="form.lat_lokasi"
                    type="text"
                    class="form-control"
                    placeholder="Latitude Lokasi"
                    required
                    @input="formValidate.lat_lokasi = ''"
                    :class="{
                      'is-valid': form.lat_lokasi != '',
                      'is-invalid':
                        formValidate.lat_lokasi || form.lat_lokasi == '',
                    }"
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label class="f-w-600" for="long_lokasi"
                    >Longitude Lokasi
                  </label>
                  <input
                    v-model="form.long_lokasi"
                    type="text"
                    class="form-control"
                    placeholder="Longitude Lokasi"
                    required
                    @input="formValidate.long_lokasi = ''"
                    :class="{
                      'is-valid': form.long_lokasi != '',
                      'is-invalid':
                        formValidate.long_lokasi || form.long_lokasi == '',
                    }"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="panel-footer">
            <button
              class="btn btn-success pull-right"
              @click="postData"
              variant="primary"
              :disabled="loading"
            >
              <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
              Submit
            </button>
            <a href="javascript:window.history.go(-1);" class="btn btn-default"
              >Kembali</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import userData from "../../../plugins/userData";

export default {
  mixins: [userData],
  data() {
    return {
      form: {
        id_lokasi: "",
        id_area: "",
        nama_lokasi: "",
        lat_lokasi: "",
        long_lokasi: "",
      },
      markers: [],
      formValidate: [],
      listMasterArea: [],
      loading: false,
    };
  },
  mounted() {
    this.getMasterArea();
    const id = this.$route.params.id;
    if (id) {
      this.fetchData(id);
    }
  },
  watch: {
    "form.lat_lokasi": "updateMarker",
    "form.long_lokasi": "updateMarker",
  },
  methods: {
    getMasterArea() {
      axios
        .get(this.$url+"/v2/area/option/")
        .then((response) => {
          this.listMasterArea = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchData(id) {
      axios
        .get(this.$url+"/v2/lokasi/detail/" + id)
        .then((response) => {
          for (let key in response.data.data) {
            this.form[key] = response.data.data[key];
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    postData() {
      this.loading = true;
      const formData = new FormData();
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }
      axios
        .post(this.$url+"/v2/lokasi/update/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              showConfirmButton: false,
            });
            setTimeout(() => {
              Swal.close();
              if (response.data.status) {
                this.$router.go(-1);
              }
            }, 700);
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.error = error.message;
        });
    },
    updateMarker() {
      if (this.form.lat_lokasi && this.form.long_lokasi) {
        const lat = parseFloat(this.form.lat_lokasi);
        const lng = parseFloat(this.form.long_lokasi);

        if (!isNaN(lat) && !isNaN(lng)) {
          this.markers = [
            {
              position: { lat, lng },
            },
          ];
          this.center = { lat, lng };
        }
      }
    },
    handleMarkerClick(event) {
      console.log("Marker clicked:", event);
    },
    handleMarkerDragEnd(event) {
      const latLng = event.latLng;
      const lat = latLng.lat();
      const lng = latLng.lng();

      // Update form data
      this.form.lat_lokasi = lat;
      this.form.long_lokasi = lng;

      // Update marker and center
      this.markers = [
        {
          position: { lat, lng },
        },
      ];
      this.center = { lat, lng };
    },
  },
};
</script>